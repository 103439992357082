import "./test.css";

import {
  Button,
  Card,
  CardBody,
  Collapse,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { GoThumbsdown, GoThumbsup } from "react-icons/go";
import React, { useState } from "react";

import { DoctorProductLocation } from "../../../locations";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import ReactTooltip from 'react-tooltip';
import { TrackObjectType } from "../../../_enums/_trackingEnum";
import { apiBaseUrl } from "../../../config.example";
import axios from "axios";
import { cogoToastHelper } from "../../../_helpers/cogoToastHelper";
import { connect } from "react-redux";
import { detailsProduct } from "../../../_actions/product.details.action";
import i18next from "i18next";
import { sourcesService } from "../../../_services/sources.service";
import { trackingServices } from "../../../_services/tracking.service";
import { userService } from "../../../_services/user.service";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

function onClickHandler(id) {
  detailsProduct(id);
}

// export default
function SingleSearchResult(props) {
  let result = props.result;

  //Collapse For External
  const [fav, setFav] = useState(0);
  const [collapse, setCollapse] = useState(false);
  const [externalUrl, setExternalUrl] = React.useState("");
  const [showMore, setshowMore] = React.useState(false);
  const [showMoreMessage, setshowMoreMessage] = React.useState('');
  const [showDetails, setShowDetails] = React.useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [selectedProductName, setSelectedProductName] = useState('');
  const [selectedProductDetails, setSelectedProductDetails] = useState('');
  const [showSwissPedDoseDetails, setShowSwissPedDoseDetails] = React.useState(false);
  const [dataSwissPedDose, setDataSwissPedDose] = useState([]);
  const [loading, setLoading] = useState(false);

  const onClickHandlerTupExternal = () => {
    cogoToastHelper.success("You liked this source");
  };

  const onClickHandlerDownExternal = () => {
    cogoToastHelper.warn("You disliked this source");
  };

  const onClickTrackUserAndProduct = (productId) => {
    onClickHandler(productId);
    trackUserInteraction(userService.getUserId(), productId);
  };

  const trackUserInteraction = (userid, productid) => {

    // result contains current product details
    var prodId = result.productid;

    // If the productid is null, then it was created by a human and not with the crawler
    // Therefore these products should be tracked
    if (prodId == null || prodId == undefined || prodId == "" || prodId == 0) {
      var trackingType = TrackObjectType.Product;

      var dto = {
        trackObjectType: trackingType,
        trackObjectId: productid,
        userId: userid
      };

      // Track the user interaction
      trackingServices.insertUserInteraction(dto);
    }
  }

  const externalHandling = (link) => {
    setExternalUrl(link.url);
    setLoading(true);

    if (link.url.includes("rote-liste")) {
      axios
        .get(`${apiBaseUrl}/v2/search/getRoteListeDetails`, {
          params: { url: link.url },
        })
        .then((response) => {
          setLoading(false);
          if (response && response.details.length > 0) {
            setShowDetails(true);
            setProductDetails(response);
          } else {
            setshowMore(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching Rote Liste details:", error);
          setshowMore(true);
        });
    } else {
      setshowMore(true);
    }
  };

  const openExternalLink = (result, element) => {
    element.target.style.color = "#b300b3";

    //set the correct message for the "show more" modal
    if (result.type === "ProductRec") {
      setshowMoreMessage(i18next.t("leave1-product"));
    } else {
      /*result.type === "Internal"*/
      setshowMoreMessage(i18next.t("leave1"));
    }
    externalHandling(result);
  }

  const calculateWidth = (type) => {
    if (type == "Internal")
      return 8;
    else
      return 12;
  };

  const SwissPedDoseDetails = async (link) => {
    setExternalUrl(link.url);
    setShowSwissPedDoseDetails(true);
    var prodId = result.productid;

    await sourcesService.getSwissPedDoseDetails(prodId).then(
      (response) => {
        // Prevent empty responses
        if (response != "") {
          var jsonSwissPedDoseDetails = JSON.stringify(response);
          var jsonObjects = JSON.parse(jsonSwissPedDoseDetails);

          // Prepare data for modal
          const arrCase = [];
          var counter = 0;
          for (var cases in jsonObjects) {
            arrCase.push(
              <tr key={cases.caseId}>
                <td>{jsonObjects[counter]["caseTitle"]}</td>
                <td>
                  {i18next.t("brandName")}: {jsonObjects[counter]["brandName"] != null ? jsonObjects[counter]["brandName"] : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("description")}: {jsonObjects[counter]["description"] != null ? jsonObjects[counter]["description"] : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("information")}: {jsonObjects[counter]["information"] != null ? jsonObjects[counter]["information"] : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("TechnicalInformation")}: {jsonObjects[counter]["technicalInformation"] != null ? jsonObjects[counter]["technicalInformation"] : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("intolerances")}: {jsonObjects[counter]["intolerances"] != null ? jsonObjects[counter]["intolerances"] : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("Indications")}: {jsonObjects[counter]["indications"] != null ? jsonObjects[counter]["indications"] : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("counterIndications")}: {jsonObjects[counter]["counterIndications"] != null ? jsonObjects[counter]["counterIndications"] : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("ageFrom")}: {jsonObjects[counter]["ageFrom"] != -1 ? jsonObjects[counter]["ageFrom"] + " " + (jsonObjects[counter]["ageFromUnit"] != null ? jsonObjects[counter]["ageFromUnit"] : i18next.t("noUnit")) : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("ageTo")}: {jsonObjects[counter]["ageTo"] != -1 ? jsonObjects[counter]["ageTo"] + " " + (jsonObjects[counter]["ageToUnit"] != null ? jsonObjects[counter]["ageToUnit"] : i18next.t("noUnit")) : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("weightFrom")}: {jsonObjects[counter]["weightFrom"] != -1 ? jsonObjects[counter]["weightFrom"] + " kg" : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("weightTo")}: {jsonObjects[counter]["weightTo"] != -1 ? jsonObjects[counter]["weightTo"] + " kg" : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("birthWeightFrom")}: {jsonObjects[counter]["birthWeightFrom"] != -1 ? jsonObjects[counter]["birthWeightFrom"] + " kg" : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("birthWeightTo")}: {jsonObjects[counter]["birthWeightTo"] != -1 ? jsonObjects[counter]["birthWeightTo"] + " kg" : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("doseType")}: {jsonObjects[counter]["doseType"] != null ? jsonObjects[counter]["doseType"] : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("lowerDoseRange")}: {jsonObjects[counter]["lowerDoseRange"] != -1 ? jsonObjects[counter]["lowerDoseRange"] + " " + (jsonObjects[counter]["doseRangeUnit"] != null ? jsonObjects[counter]["doseRangeUnit"] : i18next.t("noUnit")) : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("upperDoseRange")}: {jsonObjects[counter]["upperDoseRange"] != -1 ? jsonObjects[counter]["upperDoseRange"] + " " + (jsonObjects[counter]["doseRangeUnit"] != null ? jsonObjects[counter]["doseRangeUnit"] : i18next.t("noUnit")) : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("lowerRangeDailyRepetitions")}: {jsonObjects[counter]["lowerRangeDailyRepetitions"] != -1 ? jsonObjects[counter]["lowerRangeDailyRepetitions"] + " " + (jsonObjects[counter]["doseRangeUnit"] != null ? jsonObjects[counter]["doseRangeUnit"] : i18next.t("noUnit")) : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("upperRangeDailyRepetitions")}: {jsonObjects[counter]["upperRangeDailyRepetitions"] != -1 ? jsonObjects[counter]["upperRangeDailyRepetitions"] + " " + (jsonObjects[counter]["doseRangeUnit"] != null ? jsonObjects[counter]["doseRangeUnit"] : i18next.t("noUnit")) : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("maxSingleDose")}: {jsonObjects[counter]["maxSingleDose"] != -1 ? jsonObjects[counter]["maxSingleDose"] + " " + (jsonObjects[counter]["maxSingleDoseUnit"] != null ? jsonObjects[counter]["maxSingleDoseUnit"] : i18next.t("noUnit")) : i18next.t("noValues")}
                  <br></br>
                  {i18next.t("maxDailyDose")}: {jsonObjects[counter]["maxDailyDose"] != -1 ? jsonObjects[counter]["maxDailyDose"] + " " + (jsonObjects[counter]["maxDailyDoseUnit"] != null ? jsonObjects[counter]["maxDailyDoseUnit"] : i18next.t("noUnit")) : i18next.t("noValues")}
                </td>
              </tr>)
            counter += 1;
          }
          // Set data for modal
          setDataSwissPedDose(arrCase);
        }
      },
      (error) => {
        console.log("Error while getting SwissPedDoseDetails", error);
      }
    )
  }

  function setFavourite(result) {
    var config = {
      method: "get",
      url: apiBaseUrl + '/Search/SetFavouriteSource/' + result.domain + '',
      headers: {},
    };
    axios(config);

    result.isFavourite = true;
    setFav(fav + 1);
  }

  function removeFavourite(result) {
    var config = {
      method: "get",
      url: apiBaseUrl + '/Search/RemoveFavouriteSource/' + result.domain + '',
      headers: {},
    };
    axios(config);

    result.isFavourite = false;
    setFav(fav + 1);
  }

  function setFavouriteArticle(result) {
    axios.post(apiBaseUrl + '/Search/SetFavouriteArticle', {
      domain: result.domain,
      article: result.url
    });

    result.isFavouriteArticle = true;
    setFav(fav + 1);
  }

  function removeFavouriteArticle(result) {
    axios.post(apiBaseUrl + '/Search/RemoveFavouriteArticle', {
      domain: result.domain,
      article: result.url
    });

    result.isFavouriteArticle = false;
    setFav(fav + 1);
  }

  return (
    <div className="row search-result-tabs">
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          {/* <Spinner style={{ width: "3rem", height: "3rem" }} color="primary" /> */}
          <Loader type="ThreeDots" color="#00BFFF" />
        </div>
      )}

      {result.type === "Internal" && result.logo !== "" && (
        <div className="col-2">
          <img src={result.logo} alt="" style={{ width: '100%' }} />
        </div>
      )}

      <div className={"col-" + calculateWidth(result.type)}>
        {result.type === "Internal" ? (
          <Link
            value={result.id}
            to={DoctorProductLocation.path}
            onClick={() => onClickTrackUserAndProduct(result.id)}>
            <h3 className="heading f20">{result.title}</h3>
          </Link>
        ) : (
          <div>
            <a onClick={(e) => openExternalLink(result, e)} target="_blank"
              style={{ cursor: 'pointer', display: "inline-block", width: "95%" }}>
              <h3 className="heading f20">{result.title}</h3>
            </a>

            {result.type === "External" && (result.isFavourite ?
              (<div>
                <div style={{ display: "inline-block", position: "absolute", top: "0px", right: "0px" }}
                  onClick={() => removeFavourite(result)}
                  data-tip={i18next.t("This source is in your favourites. Click to remove from favourites.")}>
                  <i className="fa fa-star icons" style={{ color: 'orange' }}></i>
                </div>
                {result.isFavouriteArticle ?
                  <div style={{ display: "inline-block", position: "absolute", top: "30px", right: "0px" }}
                    onClick={() => removeFavouriteArticle(result)}
                    data-tip={i18next.t("This article is in your favourites. Click to remove from favourites.")}>
                    <i className="fa fa-file icons" style={{ color: 'orange' }}></i>
                  </div>
                  :
                  <div style={{ display: "inline-block", position: "absolute", top: "30px", right: "0px" }}
                    onClick={() => setFavouriteArticle(result)}
                    data-tip={i18next.t("This article is not in your favourites. Click to add to favourites.")}>
                    <i className="fa fa-file icons"></i>
                  </div>
                }
              </div>)
              :
              (<div style={{ display: "inline-block", position: "absolute", top: "0px", right: "0px" }}
                onClick={() => setFavourite(result)}
                data-tip={i18next.t("This source is not in your favourites. Click to add to favourites.")}>
                <i className="fa fa-star icons"></i>
              </div>))
            }
          </div>
        )}
        <p
          style={{
            fontSize: "14px",
            color: "#333333",
            marginBottom: "0.5em",
          }}>
          {result.owner}
          <div className="trial">
            <img alt="" src={result.companyLogo} style={{ width: '100%' }} />
          </div>
        </p>
        {ReactHtmlParser(result.description)}
        <br />
        {result.type === "Internal" && (
          <Link
            value={result.id}
            className="seemore"
            to={DoctorProductLocation.path}
            onClick={() => onClickTrackUserAndProduct(result.id)}>
            {i18next.t("seemore1")}
          </Link>
        )}

        {result.type === "ProductRec" && (
          <div>
            <a
              onClick={() => { console.log(result); externalHandling(result); }}
              className="iUh30 seemore"
              target="_blank"
              style={{
                display: "inline-block",
                position: "absolute",
              }}>
              {i18next.t("seemore")}
            </a>
            <DetailsModal
              isOpen={showDetails}
              toggle={() => setShowDetails(false)}
              modalData={productDetails}
            />
          </div>
        )}

        {/*SwissPedDose button and modal*/}
        {/* {result.type === "ProductRec" && result.containsSwissPedDose && (
              <div>
                <a
                  onClick={() => SwissPedDoseDetails(result)}
                  className="iUh30 seemore"
                  target="_blank"
                  style={{
                    display: "inline-block",
                    position: "absolute",
                    right: "0",
                    color: "#e30613",
                    borderColor: "#e30613"
                  }}>
                  {i18next.t("dosageForChildren")}
                </a>
                <Modal isOpen={showSwissPedDoseDetails} className="modal-content modal-lg">
                  <ModalHeader toggle={() => setShowSwissPedDoseDetails(false)}>SwissPedDose Details</ModalHeader>
                  <ModalBody>
                    <div>
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th>Title</th>
                          <th>Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        {dataSwissPedDose}
                        </tbody>
                      </table>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={() => {
                      setShowSwissPedDoseDetails(false);
                    }}>{i18next.t("close")}</Button>
                  </ModalFooter>
                </Modal>
              </div>
            )} */}

        {result.type === "External" && (
          <div>
            <Collapse isOpen={collapse}>
              <Card style={{}}>
                <CardBody>
                  <h1 className="heading f20">
                    <a
                      onClick={() => externalHandling(result)}
                      className="iUh30"
                      target="_blank">
                      {result.title}
                    </a>
                  </h1>
                  <a
                    href={result.url + "subscribe"}
                    style={{ color: "#1a0dab" }}
                    target="_blank">
                    Subscribe
                  </a>
                  <p>{result.description} </p>
                  <a
                    onClick={() => externalHandling(result)}
                    className="iUh30 seemore"
                    target="_blank">
                    {i18next.t("seemore")}
                  </a>
                  <br />
                  <GoThumbsup
                    className="col-4"
                    onClick={onClickHandlerTupExternal}>
                  </GoThumbsup>
                  <GoThumbsdown
                    className="col-4"
                    onClick={onClickHandlerDownExternal}
                  ></GoThumbsdown>
                </CardBody>
              </Card>
            </Collapse>
          </div>
        )}
      </div>

      {/*modal window to confirm leaving ddh*/}
      <Modal isOpen={showMore} className="modal-content modal-lg">
        <ModalHeader toggle={() => setshowMore(false)}></ModalHeader>
        <ModalBody>
          <strong>{showMoreMessage}<br />
            <strong style={{ color: '#f00' }}>{i18next.t("leave2")}</strong><br />
            {i18next.t("leave3")}</strong>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => {
            setshowMore(false);
            window.open(externalUrl)
          }}>{i18next.t("confirm")}</Button>{' '}
          <Button color="secondary" onClick={() => setshowMore(false)}>{i18next.t("cancel")}</Button>
        </ModalFooter>
      </Modal>
      <ReactTooltip />{/*this is required to show the tooltips of the favourite buttons*/}
    </div>
  );
}

const DetailsModal = ({ isOpen, toggle, modalData }) => {
  const [selectedKey, setSelectedKey] = React.useState(null);

  React.useEffect(() => {
    if (modalData && modalData.details && Object.keys(modalData.details).length > 0) {
      setSelectedKey(Object.keys(modalData.details)[0]); // Default to the first key
    }
  }, [modalData]);

  if (!modalData) return null;

  return (
    <Modal isOpen={isOpen} className="modal-content modal-xl">
      <ModalHeader toggle={toggle}>{modalData.title}</ModalHeader>
      <ModalBody style={{ height: '75vh', display: 'flex', flexDirection: 'row' }}>
        {/* Left navigation for keys */}
        <div
          style={{
            flex: 1,
            borderRight: '1px solid #ddd',
            paddingRight: '10px',
            overflowY: 'auto',
          }}
        >
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {modalData.details &&
              Object.keys(modalData.details).map((key) => (
                <li
                  key={key}
                  style={{
                    padding: '10px',
                    cursor: 'pointer',
                    backgroundColor: selectedKey === key ? '#f0f0f0' : 'transparent',
                    borderBottom: '1px solid #ddd',
                  }}
                  onClick={() => setSelectedKey(key)}
                >
                  <strong>{key}</strong>
                </li>
              ))}
          </ul>
        </div>

        {/* Right pane for content */}
        <div
          style={{
            flex: 3,
            paddingLeft: '10px',
            overflowY: 'auto',
          }}
        >
          {selectedKey ? (
            <div>{ReactHtmlParser(modalData.details[selectedKey])}</div>
          ) : (
            <p style={{ color: '#888' }}>Select a category to view details.</p>
          )}
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: 'space-between', width: '100%' }}>
        <div style={{ textAlign: 'left', flex: 1 }}>
          {i18next.t("informationByRoteListe")}
        </div>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  filter: state.product.filter,
  products: state.product.products,
});

export default connect(mapStateToProps)(SingleSearchResult);